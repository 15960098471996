import { registerApplication, start } from "single-spa";
import * as isActive from "./activity-functions";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

registerApplication(
  "@isc/navbar",
  () => System.import("@isc/navbar"),
  isActive.navbar,
  { domElement: document.getElementById("navbar") }
);

registerApplication(
  "@design/main",
  () => System.import("@design/main"),
  isActive.main,
  { domElement: document.getElementById("main") }
);

registerApplication(
  "@design/addon",
  () => System.import("@design/addon"),
  isActive.addon,
  { domElement: document.getElementById("addon") }
);

registerApplication(
  "@design/post",
  () => System.import("@design/post"),
  isActive.post,
  { domElement: document.getElementById("post") }
);

registerApplication(
  "@design/publish",
  () => System.import("@design/publish"),
  isActive.publish,
  { domElement: document.getElementById("publish")}
)

registerApplication(
  "@design/authorization",
  () => System.import("@design/authorization"),
  isActive.authorization,
  { domElement: document.getElementById("authorization")}
)


start();
