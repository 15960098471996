export function prefix(location, ...prefixes) {
  return prefixes.some(
    (prefix) => location.href.indexOf(`${location.origin}/${prefix}`) !== -1
  );
}

export function navbar() {
  return true;
}

export function main(location) {
  return prefix(location, "main");
}

export function addon(location) {
  return prefix(location, "addon");
}

export function post(location) {
  return prefix(location, "post");
}

export function publish(location) {
  return prefix(location, "publish");
}

export function authorization(location) {
  return prefix(location, "auth");
}
